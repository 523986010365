import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import expedientes from './modules/expedientes';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    url: process.env.VUE_APP_API_URL,    
    token: null,
    user: {
      id: null,
      usu_rol: null,
      name: null,
      email: null,
      token: null,
      abo_id: null,
      datos: {
        dni: null,
        apellido_paterno: null,
        apellido_materno: null,
        nombres: null,
        telefono: null,
        correo: null
      }
    }
  },

  getters: {
    // Obtener la URL del backend
    get__url: (state) => state.url,
    
    // Obtener el token de autenticación
    get__token: (state) => state.token || state.user.token,
    
    // Obtener el usuario autenticado
    get__user: (state) => state.user,

    // Obtener el ID del usuario
    get__idusu: (state) => state.user.id,
    
    // Obtener el ID del abogado
    get__aboid: (state) => state.user.abo_id
  },

  mutations: {
    // Establecer los datos del usuario
    mt_set_user(state, payload) {
      state.user = { ...state.user, ...payload };
      if (payload.token) state.token = payload.token;
    },

    // Actualizar datos del usuario (por ejemplo, tras un refresh token)
    mt_set_refresh(state, user) {
      state.user = { ...state.user, ...user };
    },

    // Establecer el ID del abogado asociado
    mt_set_aboid(state, id) {
      state.user.abo_id = id;
    },

    // Limpiar los datos del usuario al cerrar sesión
    mt_logout_auth(state) {
      state.user = {
        id: null,
        usu_rol: null,
        name: null,
        email: null,
        token: null,
        abo_id: null,
        datos: {
          dni: null,
          apellido_paterno: null,
          apellido_materno: null,
          nombres: null,
          telefono: null,
          correo: null
        }
      };
      state.token = null;
    }
  },

  actions: {
    // Acción para iniciar sesión y guardar datos en el store
    async login({ commit }, credentials) {
      try {
        const response = await axios.post(`${this.getters.get__url}/login`, credentials);
        commit('mt_set_user', response.data.user);
        return response.data;
      } catch (error) {
        console.error('Error al iniciar sesión:', error);
        throw error;
      }
    },

    // Acción para cerrar sesión
    logout({ commit }) {
      commit('mt_logout_auth');
    }
  },

  plugins: [
    createPersistedState({
      key: 'vuex-auth', // Nombre de la clave en el almacenamiento persistente
      storage: window.localStorage, // Uso de LocalStorage
      reducer: (persistedState) => {
        // Filtrar el estado persistido para incluir solo lo necesario
        const { user, token } = persistedState;
        return { user, token };
      }
    })
  ],

  modules : {
    expedientes
  }
});
