import Vue from 'vue';
import createPersistedState from 'vuex-persistedstate';

const state = () => ({
    expediente: {
        exp_numero: '',
        exp_fecha_inicio: '',
        exp_pretencion: '',
        exp_materia: '',
        exp_dis_judicial: '',
        exp_instancia: '',
        exp_especialidad: '',
        exp_monto_pretencion: '',
        exp_estado_proceso: '',
        multiple: '',
        exp_juzgado: '',
        abo_id: '',
        type_id: 1,
        carpeta_fiscal: '',
        sentencia: '',
        exp_contrato_referencia: '',
    },
    persona: {
        nat_dni: '',
        nat_apellido_paterno: '',
        nat_apellido_materno: '',
        nat_nombres: '',
        nat_telefono: '',
        nat_correo: '',
        jur_ruc: '',
        jur_razon_social: '',
        jur_telefono: '',
        jur_correo: '',
        tipo_procesal: '',
        tipo_persona: '',
        per_condicion: ''
    },
    direccion: {
        dir_calle_av: '',
        dis_id: '',
        pro_id: '',
        dep_id: '',
        per_id: ''
    },
    montos_ejecucion: {
        ex_ejecucion_1: '',
        ex_ejecucion_2: '',
        ex_interes_1: '',
        ex_interes_2: '',
        ex_costos: '',
        total_amount_sentence: '',
        total_balance_payable: ''
    },
    personas: []
})

const getters = {
    expediente: (state) => () => state.expediente,
    persona: (state) => () => state.persona,
    direccion: (state) => () => state.direccion,
    montosEjecucion: (state) => () => state.montosEjecucion,
    personas: (state) => () => state.personas,
}

const mutations = {
    actualizarExpediente(state, expediente) {
        state.expediente = expediente;
    },
    actualizarPersona(state, persona) {
        state.persona = persona;
    },
    actualizarDireccion(state, direccion) {
        state.direccion = direccion;
    },
    actualizarMontosEjecucion(state, montosEjecucion) {
        state.montos_ejecucion = montosEjecucion;
    },
    agregarPersonas(state, nuevaPersona) {
        state.personas.push(nuevaPersona);
    },
    eliminarPersonas(state, index) {
        state.personas.splice(index, 1);
    },
    actualizarPersonas(state, { index, persona }) {
        Vue.set(state.personas, index, persona);
    },
    limpiarDatosPersonaDireccion(state) {
        state.persona = {
            nat_dni: '',
            nat_apellido_paterno: '',
            nat_apellido_materno: '',
            nat_nombres: '',
            nat_telefono: '',
            nat_correo: '',
            jur_ruc: '',
            jur_razon_social: '',
            jur_telefono: '',
            jur_correo: '',
            tipo_procesal: '',
            tipo_persona: '',
            per_condicion: ''
        };
        state.direccion = {
            dir_calle_av: '',
            dis_id: '',
            pro_id: '',
            dep_id: '',
            per_id: ''
        };
    },
    limpiarDatos(state) {
        state.expediente = {
            exp_numero: '',
            exp_fecha_inicio: '',
            exp_pretencion: '',
            exp_materia: '',
            exp_dis_judicial: '',
            exp_instancia: '',
            exp_especialidad: '',
            exp_monto_pretencion: '',
            exp_estado_proceso: '',
            multiple: '',
            exp_juzgado: '',
            abo_id: '',
            type_id: 1,
            carpeta_fiscal: '',
            sentencia: '',
            exp_contrato_referencia: '',
        };
        state.persona = {
            nat_dni: '',
            nat_apellido_paterno: '',
            nat_apellido_materno: '',
            nat_nombres: '',
            nat_telefono: '',
            nat_correo: '',
            jur_ruc: '',
            jur_razon_social: '',
            jur_telefono: '',
            jur_correo: '',
            tipo_procesal: '',
            tipo_persona: '',
            per_condicion: ''
        };
        state.direccion = {
            dir_calle_av: '',
            dis_id: '',
            pro_id: '',
            dep_id: '',
            per_id: ''
        };
        state.montos_ejecucion = {
            ex_ejecucion_1: '',
            ex_ejecucion_2: '',
            ex_interes_1: '',
            ex_interes_2: '',
            ex_costos: '',
            total_amount_sentence: '',
            total_balance_payable: ''
        };
        state.personas = [];
    }
}

const plugins = [
    createPersistedState({
        key: 'vuex-expedientes',

        storage: window.localStorage,
        reducer: (state) => {
            // Filtra los datos que quieres persistir
            console.log('State before persisting:', state);
            return {
                expediente: state.expediente,
                persona: state.persona,
                direccion: state.direccion,
                montos_ejecucion: state.montos_ejecucion,
                personas: state.personas
            };
        }
    })
]

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    plugins,
}