<template>
    <div class="table-responsive">
        <table  ref="dataTable" class="table border-top-0" style="width: 100%;">
            <thead class="bg-primary border-primary">
                <slot name="header"></slot>
            </thead>
            <tbody>
                <slot name="body"></slot>
            </tbody>
        </table>
    </div>
</template>

<script>
import $ from "jquery";
import 'datatables.net-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';

import DataTablesES from '@/data/DataTables-ES.json'; // Datos de idioma (opcional)

export default {
    name: "DataTable",
    mounted() {
        // Inicializar DataTable
        this.initializeDataTable();
    },
    beforeDestroy() {
        // Destruir el DataTable para evitar fugas de memoria
        if (this.$dataTable) {
            this.$dataTable.destroy();
        }
    },
    methods: {
        initializeDataTable() {
            // Opciones básicas para DataTable
            const options = {
                paging: true,
                searching: true,
                info: true,
                ordering: true,
                language: DataTablesES // Opcional, solo si deseas traducción al español
            };

            // Inicializar el DataTable en el elemento table
            this.$dataTable = $(this.$refs.dataTable).DataTable(options);
        },
    },
};
</script>

<style scoped>
/* Opcional: Estilos adicionales para ajustar el diseño */
table {
    border-collapse: collapse;
    width: 100%;
}

/* Estilo adicional para que los bordes de la tabla se vean mejor */
table.dataTable {
    border: 1px solid #dee2e6;
}
</style>